import React from "react"
import classnames from "classnames"
import { useWindowSize } from "react-use"

/* Import Global Components */

import QrCode from "~components/qrCode/qrCode"

import Timeline from "~components/timeline/timeline"

import * as styles from "./footer.module.scss"

const Footer = ({ children }) => {
  const { width: windowWidth } = useWindowSize()

  return (
    <div className={classnames("footer-container", styles.footerContainer)}>
      {windowWidth > 768 && (
        <div
          style={{
            position: "fixed",
            left: "16px",
            height: "100%",
            display: "flex",
            top: "0",
            alignItems: "center",
          }}
        >
          <QrCode />
        </div>
      )}
      <div className={classnames("subtitles", styles.subtitlesContainer)}>
        <div className={classnames("subtitles", styles.subtitles)}>
          {children}
        </div>
      </div>
      <Timeline />
    </div>
  )
}

export default Footer
