import React, { useState, useEffect } from "react"
import classnames from "classnames"

/* Import Global Queries*/
import usePerformanceConfiguration from "~queries/usePerformanceConfiguration"

/* import */
import usePlayheadPosition from "~hooks/usePlayheadPosition"

import * as styles from "./timeline.module.scss"

const formatSeconds = secondsToFormat => {
  var sec_num = parseInt(secondsToFormat, 10) // don't forget the second param
  var hours = Math.floor(sec_num / 3600)
  var minutes = Math.floor((sec_num - hours * 3600) / 60)
  var seconds = sec_num - hours * 3600 - minutes * 60

  if (hours < 10) {
    hours = "0" + hours
  }
  if (minutes < 10) {
    minutes = "0" + minutes
  }
  if (seconds < 10) {
    seconds = "0" + seconds
  }
  return minutes + ":" + seconds
}

const Timeline = () => {
  const { length: performanceLength } = usePerformanceConfiguration()
  const playheadPosition = usePlayheadPosition()
  const tickSpeed = 100 // milliseconds
  const [higherTickPlayheadPosition, setHigherTickPlayheadPosition] = useState(
    (Date.now() / 1000) % performanceLength
  )

  useEffect(() => {
    const interval = setInterval(() => {
      const currentUnixTime = Date.now() / 1000
      setHigherTickPlayheadPosition(currentUnixTime % performanceLength)
      // console.log("Performance Length: " + performanceLength)
    }, tickSpeed)
    return () => clearInterval(interval)
  }, [])

  return (
    <div className={classnames("timeline-container", styles.timelineContainer)}>
      <div className={classnames("timeline", styles.timeline)}>
        <div
          className={classnames("playhead", styles.playhead)}
          style={{
            left: `${(higherTickPlayheadPosition / performanceLength) * 100}%`,
          }}
        ></div>
        <div className={classnames("timeline-marks", styles.timelineMarks)}>
          <div
            className={classnames(
              "timeline-marks--tall",
              styles.timelineMarksTall
            )}
          >
            <div
              className={classnames(
                "timeline-marks--medium",
                styles.timelineMarksMedium
              )}
            >
              <div
                className={classnames(
                  "timeline-marks--short",
                  styles.timelineMarksShort
                )}
              ></div>
              <div
                className={classnames(
                  "timeline-marks--short",
                  styles.timelineMarksShort
                )}
              ></div>
            </div>
            <div
              className={classnames(
                "timeline-marks--medium",
                styles.timelineMarksMedium
              )}
            >
              <div
                className={classnames(
                  "timeline-marks--short",
                  styles.timelineMarksShort
                )}
              ></div>
              <div
                className={classnames(
                  "timeline-marks--short",
                  styles.timelineMarksShort
                )}
              ></div>
            </div>
            <div
              className={classnames(
                "timeline-marks--medium",
                styles.timelineMarksMedium
              )}
            >
              <div
                className={classnames(
                  "timeline-marks--short",
                  styles.timelineMarksShort
                )}
              ></div>
              <div
                className={classnames(
                  "timeline-marks--short",
                  styles.timelineMarksShort
                )}
              ></div>
            </div>
          </div>
          <div
            className={classnames(
              "timeline-marks--tall",
              styles.timelineMarksTall
            )}
          >
            <div
              className={classnames(
                "timeline-marks--medium",
                styles.timelineMarksMedium
              )}
            >
              <div
                className={classnames(
                  "timeline-marks--short",
                  styles.timelineMarksShort
                )}
              ></div>
              <div
                className={classnames(
                  "timeline-marks--short",
                  styles.timelineMarksShort
                )}
              ></div>
            </div>
            <div
              className={classnames(
                "timeline-marks--medium",
                styles.timelineMarksMedium
              )}
            >
              <div
                className={classnames(
                  "timeline-marks--short",
                  styles.timelineMarksShort
                )}
              ></div>
              <div
                className={classnames(
                  "timeline-marks--short",
                  styles.timelineMarksShort
                )}
              ></div>
            </div>
            <div
              className={classnames(
                "timeline-marks--medium",
                styles.timelineMarksMedium
              )}
            >
              <div
                className={classnames(
                  "timeline-marks--short",
                  styles.timelineMarksShort
                )}
              ></div>
              <div
                className={classnames(
                  "timeline-marks--short",
                  styles.timelineMarksShort
                )}
              ></div>
            </div>
          </div>
          <div
            className={classnames(
              "timeline-marks--tall",
              styles.timelineMarksTall
            )}
          >
            <div
              className={classnames(
                "timeline-marks--medium",
                styles.timelineMarksMedium
              )}
            >
              <div
                className={classnames(
                  "timeline-marks--short",
                  styles.timelineMarksShort
                )}
              ></div>
              <div
                className={classnames(
                  "timeline-marks--short",
                  styles.timelineMarksShort
                )}
              ></div>
            </div>
            <div
              className={classnames(
                "timeline-marks--medium",
                styles.timelineMarksMedium
              )}
            >
              {" "}
              <div
                className={classnames(
                  "timeline-marks--short",
                  styles.timelineMarksShort
                )}
              ></div>
              <div
                className={classnames(
                  "timeline-marks--short",
                  styles.timelineMarksShort
                )}
              ></div>
            </div>
            <div
              className={classnames(
                "timeline-marks--medium",
                styles.timelineMarksMedium
              )}
            >
              {" "}
              <div
                className={classnames(
                  "timeline-marks--short",
                  styles.timelineMarksShort
                )}
              ></div>
              <div
                className={classnames(
                  "timeline-marks--short",
                  styles.timelineMarksShort
                )}
              ></div>
            </div>
          </div>
          <div
            className={classnames(
              "timeline-marks--tall",
              styles.timelineMarksTall
            )}
          >
            <div
              className={classnames(
                "timeline-marks--medium",
                styles.timelineMarksMedium
              )}
            >
              {" "}
              <div
                className={classnames(
                  "timeline-marks--short",
                  styles.timelineMarksShort
                )}
              ></div>
              <div
                className={classnames(
                  "timeline-marks--short",
                  styles.timelineMarksShort
                )}
              ></div>
            </div>
            <div
              className={classnames(
                "timeline-marks--medium",
                styles.timelineMarksMedium
              )}
            ></div>
            <div
              className={classnames(
                "timeline-marks--medium",
                styles.timelineMarksMedium
              )}
            >
              {" "}
              <div
                className={classnames(
                  "timeline-marks--short",
                  styles.timelineMarksShort
                )}
              ></div>
              <div
                className={classnames(
                  "timeline-marks--short",
                  styles.timelineMarksShort
                )}
              ></div>
            </div>
          </div>
        </div>
      </div>
      {/* Playhead Position */}
      <div className={classnames("timecode", styles.timecode)}>
        <div className={classnames("timecode", styles.timecodeStart)}>
          00:00
        </div>
        <div className={classnames("timecode", styles.timecodeCurrent)}>
          {playheadPosition
            ? formatSeconds(playheadPosition.toString())
            : "0000"}
        </div>
        <div className={classnames("timecode", styles.timecodeEnd)}>
          {formatSeconds(performanceLength.toString())}
        </div>
      </div>
    </div>
  )
}

export default Timeline
